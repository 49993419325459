import GSAP from 'gsap'

import Animation from 'classes/Animation'

//import { calculate, split } from 'utils/text'

export default class Paragraph extends Animation {
  constructor ({element, elements}) {
    super({
      element,
      elements
    })

    // this.elementLinesSpans = split({
    //   append: true,
    //   element: this.element
    // })
  }

  animateIn () {
    this.timelineIn = GSAP.timeline({
      delay: 0.5
    })

    this.timelineIn.to(this.element, {
      autoAlpha: 1,
      duration: 1
    })

  }


  animateOut () {
    GSAP.set(this.element, {
      autoAlpha: 0
    })
  }

  onResize () {
  //  this.elementsLines = calculate(this.elementLinesSpans)
  }
}
